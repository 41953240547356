<template>
    <div>
      <div class="pr-2" v-if="$router.currentRoute.name !== 'agents'">
        <v-breadcrumbs :items="breadcrumbs">
          <template v-slot:divider>
            <v-icon>chevron_right</v-icon>
          </template>
        </v-breadcrumbs>
      </div>
      <div v-if="$router.currentRoute.name === 'agents'">
        <br />
        <h1 class="text-xs-center">Agents</h1>
        <agent-management
          :signed-in-user="signedInUser"
          :on-create-agent-clicked="onCreateAgentClicked"
          :on-agent-selected="onAgentSelected"
          :on-archived-agent-selected="onAgentSelected"
          :on-invited-agent-selected="onAgentSelected"
        />
      </div>
      <router-view :signed-in-user="signedInUser" />
    </div>
</template>

<script>
import src from './src'
export default src
</script>
